import React, { Component } from 'react';
import imperativeFetch from '../services/imperativeFetch';

const AuthContext = React.createContext();
export default AuthContext;


const getStartupState = () => {
	const storedToken=window.localStorage.accessToken;
	//console.log("stored token ["+storedToken+"]");
	if (storedToken!==null && (typeof storedToken==='string') && storedToken.length>0) {
		const parsedToken=parseJWT(storedToken);
		return {
			user: parsedToken.username,
			token: storedToken,
			expiry: parsedToken.exp
		}
	} else {
		return {
			user: '??',
			token: null,
			expiry: null
		}
	}
}

const parseJWT = token => {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));
	return JSON.parse(jsonPayload);
}

const saveTokenAsCookie = (token) => {
	var d = new Date();
    d.setTime(d.getTime() + (24*60*60*1000)); //24hour
    var expires = "expires="+ d.toUTCString();
    document.cookie = "token=" + token + "; " + expires;
	//console.log("setting cookie");
}

export class AuthProvider extends Component {
    state = getStartupState();

    render() {
        return (
            <AuthContext.Provider
                value={{
					user: this.state.user,
					token: this.state.token,
					isAuthenticated: () => { return this.state.token!=null },
					timeToLive: () => {
						const expiry=this.state.expiry;
						const now=new Date().getTime()/1000;
						//console.log(expiry+" "+now)
						if (expiry===null) {
							return 0;
						}
						else {
							let ttl=expiry-now;
							if (ttl<0) ttl=0;
							else ttl=Math.floor(ttl);
							return ttl;
						}
					},
					ttlThreshold: 30,
					login: (token) => {
						//console.log(token);
						const parsedToken=parseJWT(token);

						window.localStorage.accessToken=token;
						saveTokenAsCookie(token);
						this.setState({ user: parsedToken.username, token: token, expiry: parsedToken.exp })
					},
					logout: () => {
						if (this.state.token!=null) this.setState({ user: '', token: null, expiry: null })
						window.localStorage.accessToken='';
					},
					renew: async () => {
						const data = new URLSearchParams();
						data.set('token', this.state.token );
						let resp = imperativeFetch('/api/v1/renew', { method: 'post', body: data }, null);
						let newToken="??";
						await resp.then((res) => {
							if (res.response && res.response.ok) {
								const response=res.asJSON;
								if (response.success) {
									const token=response.message;
									const parsedToken=parseJWT(token);
									window.localStorage.accessToken=token;
									saveTokenAsCookie(token);
									this.setState({ user: parsedToken.username, token: token, expiry: parsedToken.exp })
									newToken=token;
								}
								else {
									this.setState({ user: '', token: null, expiry: null });
									throw new Error("Failed to renew authorization token: "+response.message);
								}
							}
							else {
								this.setState({ user: '', token: null, expiry: null });
								window.localStorage.accessToken='';
								throw new Error("Failed to renew authorization token");
							}
						}
						);
						return newToken;
					}
                }}
            >
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}