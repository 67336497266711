import React from "react";
import useFetch from '../services/useFetch';
import ServerRequestAlert, { Alert } from '../components/ServerRequestAlert';
import ValidityIcon from '../components/validityIcon';
import Breadcrumb from '../components/breadcrumb'

export default (props) => {

  const fetchResponse = useFetch('/api/v1/licensing/licenseInfo', {
    method: 'get',
  });
  // eslint-disable-next-line no-unused-vars
  var {response,loading,error,asJSON} = fetchResponse;


  

  const LicenseTable = (props) => {

    //console.log(props);
    if (props.data && props.data.product)
    {
      if (props.data.product.length>0) {
      //sort first by validity then date
      var sortedProducts = [].concat(props.data.product)
      .sort((a, b) => { 
        if (a.valid===b.valid)
        { return a.timestamp<b.timestamp;
        }
        else if (a.valid)
        { return -1;
        }
        else
        { return 1;
        }
      });
    return (
        <table className="table table-dark table-striped">

          <thead><tr>
            <th>Product</th>
            <th>Date</th>
            <th>Type</th>
            <th>Status</th>
            <th>Activations</th>
          </tr></thead>
          <tbody>
            { sortedProducts.map(function(product, index){
                return <><tr key={index} className={product.valid?"valid":"invalid"}>{/* key is needed to keep react happy */}
                  <td >{product.name}</td>
                  <td>{product.timestamp}</td>
                  <td>{product.type}</td>
                  <td>
                    <ValidityIcon valid={product.valid} />
                    {product.status}
                  </td>
                  <td>
                    {product.activeActivations}
                    <span> </span>
                    <button className="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target={"#product"+index} >view</button>
                  </td>
                </tr>
                <tr><td colSpan={5}>
                  <div className="collapse" id={"product"+index}><div className="card card-body">
                    <ActivationTable data={product}/>
                  </div></div>
                </td></tr></>;
              })
            }
          </tbody>

        </table>
    )} else {
        return (<Alert class="alert-info" title="You have no product licenses yet" msg={
          <div><p>Licenses will be created when you activate evaluations after downloading, or when you purchase products.</p>
          <p>To purchase products please visit the <a href={process.env.REACT_APP_MAIN_SITE}>product page</a>.</p></div>
        } />);
      }
    }
    else return ( <div/> );
  };

  const ActivationTable = (props) => {
    console.log(props.data);
    if (props.data && props.data.activation)
    {
      return (
      <div>
        <table className="table">
          <thead><tr>
            <th>Date</th>
            <th>Type</th>
            <th>Expires</th>
            <th>Version</th>
            <th>Hardware Id</th>
            <th>IP address</th>
          </tr></thead>
          <tbody>
            { props.data.activation.map(function(activation, index){
                return <tr key={index} className={activation.valid?"valid":"invalid"}>{/* key is needed to keep react happy */}
                  <td >{activation.timestamp}</td>
                  <td >{activation.type}</td>
                  <td >
                    <ValidityIcon valid={activation.valid} />
                    {activation.expiry.length>0?activation.expiry:"never"}
                  </td>
                  <td >{activation.version}</td>
                  <td >{activation.machine}</td>
                  <td >{activation.remote_host}</td>
                </tr>
            })}
          </tbody>
        </table>
      </div>);
    }
    else {
      return (<div></div>);
    }
  };
    

  //console.log(asJSON);
  return (
    <div>
      <Breadcrumb path={ [ {name: "Licenses"}]} />
      <div className="container top-margin">
        <div className="row">
          <div className="col-sm-12">
            <ServerRequestAlert response={fetchResponse} />
            <LicenseTable data={asJSON}/>
          </div>
        </div>
      </div>
    </div>
  );
};