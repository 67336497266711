/*index.jsx*/
import React from "react";
import Breadcrumb from '../components/breadcrumb'
import AccountSummary from '../components/accountSummary'
import { Link } from "react-router-dom";

//Functional Component 
const MainPage = () => {
  /*
  const useAuth = useContext(AuthContext);
  const isLoggedIn=useAuth.isAuthenticated();
  if (!isLoggedIn) {
    return <Redirect to='/login'  />
  }*/

  return (
    <div>
      <Breadcrumb path={ [ {name: "Index"}]} />
      <div className="header" style={{ backgroundSize: "cover", minHeight: "200px" }}>
        <div className="container">
          <div className="row">
            <h1 className="dropshadow">My Account</h1>
          </div>
        </div>
      </div>
      <div className="container top-margin">
        <div className="row">
          <div className="col-sm-12">
              <AccountSummary />
              <br/>
              <div><Link to="/downloads">View Downloads</Link></div>
              <div><Link to="/licenses">View my Licenses</Link></div>
              <div><Link to="/orders">View my Orders</Link></div>
              <div><Link to="/store">Store</Link></div>

              
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;