import React from "react";
import { useContext, useEffect, useState } from "react";
import AuthContext from '../services/authentication';
import Breadcrumb from '../components/breadcrumb';
import { Alert } from '../components/ServerRequestAlert';
import { useFastspring } from '../components/use-fastspring/index';
import { Helmet } from "react-helmet";

export default (props) => {
  const [renderComponentB, setRenderComponentB] = useState(false);
  useEffect(() => {
    //setRenderComponentB(true);
  }, []);
  /*
  const { loaded, fastspring } = useFastspring({
    accessKey: 'XXXXXXXXXXXXXXXXX-XXX',
    storefront: 'tsuga.test.onfastspring.com/embedded-tsuga',
    // optional
    onPopupClose: () => {
      // do something
    }
  });*/

  const onBuy = () => {
    console.log("buy");
    setRenderComponentB(true);
  };

  return (
    <div>
      <Breadcrumb path={ [ {name: "Store"}]} />
      
      <div className="container top-margin">
        <Alert title="Embedded Storefront!" className="alert-info"/>
        <button onClick={onBuy} className="btn btn-success">Buy Embedded<i className="fas fa-shopping-cart"></i></button>
        <span> </span>
        <button data-fsc-item-path-value="envosound" data-fsc-action="Add, Checkout" className="btn btn-success">Buy Popup<i class="fas fa-shopping-cart"></i></button>

        
        {renderComponentB && <div id="fsc-embedded-checkout-container"></div>}
        
      </div>
      {/*}
      <Helmet>
          <script id="fsc-api"
              src="https://sbl.onfastspring.com/sbl/0.9.5/fastspring-builder.min.js"
              type="text/javascript"
              data-storefront="tsuga.test.onfastspring.com/popup-tsuga">
          </script>
          
      </Helmet>
      */}
    </div>
  );
};
