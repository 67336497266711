//import AuthContext from '../services/authentication';
//import { useContext } from "react";

export default async (url,opts,useAuth) => {
    
    if (useAuth && useAuth!=null) {
        let authToken=useAuth.token;

        //renew authToken if needed
        const ttl=useAuth.timeToLive();
        if (ttl<useAuth.ttlThreshold) {
            console.log("auth token is expiring in "+ttl+"s attempting to renew...");
            const renewStatus= await useAuth.renew().then( (newToken) => {
                authToken=newToken;
                return null;
            }).catch( e=> {
                return {response:null,error:e,asJSON:null};
            });
            if (renewStatus!==null) {
                return renewStatus;
            }
        }
        
        if (!opts.headers) {
            opts.headers=new Headers({Authorization: authToken});
        } else {
            opts.headers.set('Authorization',authToken);
        }
    }

    const apiHost = process.env.REACT_APP_API_HOST;
    const fullURL=apiHost+url;
    let resp= fetch(fullURL, opts).then( async (res) => {
        const contentType=res.headers.get("Content-Type");
        if (contentType!==null && contentType==="application/json") {
            const json=await res.json();
            return {response:res,error:null,asJSON:json};
        } else if (contentType!==null && contentType.startsWith("text/plain")) {
            const text=await res.text();
            return {response:res,loading:false,error:null,asJSON:text};
        }
        else {
            return {response:res,error:null,asJSON:null};
        }
    })
    .catch( e => {
        return {response:null,error:e,asJSON:null};
    })
    ;

    return resp;
}