import React from "react";
import { Link } from "react-router-dom";

export default () => {
  return (
    <div className="container">
      <div className="row">
        <div><Link to="/cc">Class Component (requires auth)</Link></div>
        <div><Link to="/testAuth">Test Authorization</Link></div>
        <div><Link to="/formValidation">Form Validation Test</Link></div>
      </div>
    </div>
  );
};
