import React from "react";

const NarrowForm = (props) => {
  return (
	<div className="narrow-form">
		<div className='row justify-content-center'>
			<div className='col-lg-6 form-outline'>
				{props.children}
			</div>
		</div>
	</div>
  );
};


export default NarrowForm;