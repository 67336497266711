import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import AuthContext from '../services/authentication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
//import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'

export default (props) => {

  const collapse = () => {
		var toggle=document.getElementById("toggler");
		if (toggle.offsetParent != null)
		{	toggle.click();
		}
	};

  const useAuth = useContext(AuthContext);
  const isLoggedIn=useAuth.isAuthenticated();
  if (isLoggedIn) {
    return (
      <span className="dropdown">
        <button className="btn btn-dark dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <FontAwesomeIcon icon={faUser} />
        {useAuth.user}
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <Link className="dropdown-item" to="/profile" onClick={collapse}>My Profile</Link>
          <Link className="dropdown-item" to="/logout" onClick={collapse}>Logout</Link>
        </div>
      </span>
    );
  } else {
    return (<span className="btn-group">
      <Link className="btn btn-primary" to='/register' onClick={collapse}>Register</Link> 
      <Link className="btn btn-light" to='/login' onClick={collapse}>Login</Link>
      </span> );
  }
};