import React from "react";
import { Link } from "react-router-dom";

export default (props) => {
	var allButLast = [{ name: "Tsuga Audio", url: process.env.REACT_APP_MAIN_SITE}, { name: "Accounts", to: "/"}].concat(props.path);
	var popped = allButLast.pop();
	return (
		<div className="breadcrumb2-holder">
        	<div className="container">
				<div className="row">
					<div>
						<div className="breadcrumb2">
						<div><ol>
						{ allButLast.map(function(path, index) {
							if (path.to) return (
								<li key={index}>
									<Link to={path.to}>{path.name}</Link>
								</li>
							);
							if (path.url) return (
								<li key={index}>
									<a href={path.url}>{path.name}</a>
								</li>
							);
							return <div/>
						} ) }
							
							<span>{popped.name}</span>
						</ol></div>
						
					</div></div>
				</div>
			</div>
      </div>
	);
};