/*index.jsx*/
import React from "react";
import { useContext, useEffect } from "react";
import AuthContext from '../services/authentication';
import Breadcrumb from '../components/breadcrumb';
import { Alert } from '../components/ServerRequestAlert';

export default (props) => {
  
  const useAuth = useContext(AuthContext);
  useEffect(() => {
    useAuth.logout();
  });
  
  return (
    <div>
      <Breadcrumb path={ [ {name: "Logout"}]} />
      <div className="container top-margin">
        <Alert title="You are now logged out." class="alert-info"/>
      </div>
    </div>
  );
};
