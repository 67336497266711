import { useState, useEffect, useContext } from "react";
import AuthContext from '../services/authentication';

export default (url, opts) => {
    const defaultValue = opts.defaultValue?opts.defaultValue:"";
    const needAuth = (opts.needAuth!==undefined)?opts.needAuth:true;
    const [state, setState] = useState({response:null,loading:true,error:null,asJSON:defaultValue })
    const useAuth = useContext(AuthContext);

    useEffect( () => {
        async function fetchData() {
            if (state.response!==null || state.error!==null) {
                setState({response:null,loading:true,error:null,asJSON:null});
            }
            
            
            let authToken = useAuth.token;
            //renew authToken if needed
            const ttl=useAuth.timeToLive();
            if (ttl<useAuth.ttlThreshold && needAuth) {
                console.log("auth token is expiring in "+ttl+" attempting to renew...");
                const renewStatus=await useAuth.renew().then( (newToken) => {
                    authToken=newToken;
                    return null;
                }).catch( e=> {
                    return e;
                });
                if (renewStatus!==null) {
                    setState({response:null,loading:false,error:renewStatus,asJSON:null});
                    return;
                }
            }

            if (needAuth || ttl>0 ) {
                if (!opts.headers) {
                    opts.headers=new Headers({Authorization: authToken});
                }
                else {
                    opts.headers.set('Authorization',authToken);
                }
            }

            const apiHost = process.env.REACT_APP_API_HOST;
            const fullURL=apiHost+url;
            fetch(fullURL, opts)
                .then((res) => {
                const contentType=res.headers.get("Content-Type");
                if (contentType!==null && contentType==="application/json") {
                    res.json().then(data=>{
                        setState({response:res,loading:false,error:null,asJSON:data});
                    })
                }
                else if (contentType!==null && contentType.startsWith("text/plain")) {
                    res.text().then(data=>{
                        setState({response:res,loading:false,error:null,asJSON:data});
                    })
                }
                else {
                    setState({response:res,loading:false,error:null,asJSON:null});
                }
            })
            .catch((e) => {
                setState({response:null,loading:false,error:e,asJSON:null});
            })
        }
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ url ])
        return state;
    
}