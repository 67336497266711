import React from "react";
import { ErrorMessage, useFormikContext } from 'formik'

function getClasses(name, errors) {
	if (errors.hasOwnProperty(name)) {
		return "is-invalid form-control";
	} else {
		return "form-control";
	}
}

export const customTextInput = ({ field, form: { errors } }) => {
	return <div>
		<input {...field} className={getClasses(field.name, errors)} />
		<div className="field-errors"><ErrorMessage name={field.name} /></div>
	</div>
}

export const customPasswordInput = ({ field, form: { errors } }) => {
	return <div>
		<input {...field} className={getClasses(field.name, errors)} type="password"/>
		<div className="field-errors"><ErrorMessage name={field.name} /></div>
	</div>
}

export const CustomInput = (props) => {
	//console.log(props);
	const name=props.children.props.name;
	const { errors } = useFormikContext();
	const label=props.label?props.label:name;
	let c=React.cloneElement(props.children, { className:getClasses(name, errors)});
	return <div className="form-group">
		<label htmlFor={name}>{label}</label>
		{c}
		<div className="field-errors"><ErrorMessage name={name} /></div>
	</div>;
}

export const CustomCheckbox = (props) => {
	const name=props.children.props.name;
	const label=props.label?props.label:name;
	let c=React.cloneElement(props.children, { className: "form-check-input" });
	return <div className="form-group">
		{c} <label htmlFor={name} className="form-check-label">{label}</label>
  </div>;
}