import React, { Component } from 'react';

import './App.css';
import Template from './components/pageTemplate'
import { AuthProvider } from './services/authentication';

import {
  BrowserRouter,
  Route,
  Redirect,
  Switch
} from "react-router-dom";

import MainPage from "./pages/index"; ///< index.jsx will be automatically imported 
import SignupPage from "./pages/signup";
import SignupPasswordReset from './pages/signupPasswordReset';
import SignupCompletePage from "./pages/signupComplete";

import LoginPage from "./pages/login";
import Forgot1Page from "./pages/forgot1";
import Forgot2Page from "./pages/forgot2";
import LogoutPage from "./pages/logout";
import AccountPage from "./pages/account";
import NotFoundPage from "./pages/404";

import DeveloperIndexPage from "./pages/developer";
import FormValidationTestPage from "./pages/formValidationTest";
import TwoFactorAuthPage from "./pages/2fa";
import TestAuthPage from "./pages/testAuth";
import ClassPage from "./pages/classpage";

import LicensePage from "./pages/licenses";
import DownloadPage from "./pages/downloads";
import DownloadAction from "./pages/downloadAction";
import OrderPage from "./pages/orders";
import PurchaseFreePage from "./pages/purchaseFree";
import EmbeddedStorefront from "./pages/embeddedStorefront";

const hasAuthToken= function() {
  const token=window.localStorage.getItem('accessToken');
  const result= (typeof token === 'string' || token instanceof String) && token.length>0;
  return result;
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  
  <Route {...rest} render={(props) => (
    hasAuthToken() === true
      ? <Component {...props} />
      : <Redirect to={{ pathname: '/login', state: { redirect: props.location } }}/>
  )} />
)

class App extends Component {

  render() {
    var basepath = process.env.REACT_APP_BASEPATH;
    var router=
      <Switch>
        
       {/*All our Routes go here!*/}
       <PrivateRoute exact path="/" component={MainPage} />
       <Route exact path="/register" component={SignupPage} />
       <Route exact path="/signupPasswordReset" component={SignupPasswordReset} />
       <Route exact path="/signupComplete" component={SignupCompletePage} />
       <Route exact path="/login" component={LoginPage} />
       <Route exact path="/forgot" component={Forgot1Page} />
       <Route exact path="/passwordReset" component={Forgot2Page} />
       <Route exact path="/logout" component={LogoutPage} />
       <PrivateRoute exact path="/2fa" component={TwoFactorAuthPage} />
       <PrivateRoute exact path="/profile" component={AccountPage} />
       
       <PrivateRoute exact path="/developer" component={DeveloperIndexPage} />
       <PrivateRoute exact path="/formValidation" component={FormValidationTestPage} />
       <Route exact path="/testAuth" component={TestAuthPage} />
       <PrivateRoute exact path="/cc" component={ClassPage} />

       <PrivateRoute exact path="/licenses" component={LicensePage} />
       <Route exact path="/downloads" component={DownloadPage} />
       <Route exact path="/downloadStarted" component={DownloadAction} />
       <PrivateRoute exact path="/orders" component={OrderPage} />
       <PrivateRoute path="/purchase-free" component={PurchaseFreePage} />

       <Route exact path="/store" component={EmbeddedStorefront} />

       <Route exact path="/404" component={NotFoundPage} />
       <Route><Redirect to="/404"/></Route>
      </Switch>;

    return (
      <AuthProvider>
        <BrowserRouter basename={basepath} >
          <Template content={router}/>
        </BrowserRouter>
      </AuthProvider>
    );
  }
}

export default App;