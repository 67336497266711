import React from "react";
import useFetch from '../services/useFetch';
import ServerRequestAlert from '../components/ServerRequestAlert';
import { Link } from "react-router-dom";

export default (props) => {

	const fetchResponse = useFetch('/api/v1/account', {
	  method: 'get',
	});
	// eslint-disable-next-line no-unused-vars
	var {response,loading,error,asJSON} = fetchResponse;

	const SummaryBlock = (props) => {
		//console.log(props);
		if (props.data && props.data.email)
		{	return (
				<div>
					<div className="row">
						<div className="col-9">
							<div style={{fontWeight: "bold"}}>{asJSON.firstName}<span> </span>{asJSON.lastName}</div>
							<div>{asJSON.email}</div>
						</div>
						<div className="col-3">
						<Link to="/profile" className="btn btn-secondary">Edit my profile</Link>
						</div>
					</div>
				</div>
			
			);
		} else {
			return (<div/>);
		}
	}

	return (
		<div>
			<ServerRequestAlert response={fetchResponse} />
			<SummaryBlock data={asJSON}/>
		</div>
		);
};