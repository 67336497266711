import React from "react";
import NarrowForm from '../components/narrowForm';
import { Formik, Form, Field } from 'formik'
import { Link } from "react-router-dom";
import useFetch from '../services/useFetch';
import imperativeFetch from '../services/imperativeFetch';
import { CustomInput, CustomCheckbox } from '../components/customInput';
import ServerRequestAlert, { setFetchResponseError, resetFetchResponseError, Alert } from '../components/ServerRequestAlert';
import { useContext } from "react";
import AuthContext from '../services/authentication';
import FormikSubmit from '../components/formikSubmit';
import Breadcrumb from '../components/breadcrumb'

export default (props) => {
  const useAuth = useContext(AuthContext);
  const fetchResponse = useFetch('/api/v1/account', {
    method: 'get',
  });
  // eslint-disable-next-line no-unused-vars
  var {response,loading,error,asJSON} = fetchResponse;

  const onSubmit = (values, actions) => {
    resetFetchResponseError('#form-error');
    var data = new URLSearchParams(values);
    let resp = imperativeFetch('/api/v1/account', { method: 'post', body: data }, useAuth);
		resp.then((res) => {
			setFetchResponseError(res, '#form-error',"Account details updated");
      actions.setSubmitting(false);
      actions.resetForm({ values });
    });
  };
  
  return (
    <div>
      <Breadcrumb path={ [ {name: "Profile"}]} />
      <div className="container">
      <NarrowForm>
        <Formik initialValues={asJSON} onSubmit={onSubmit} enableReinitialize={true} >
        {formik => {
          return (
            <Form>
              <div className="form-group">
                  <label >email</label>
                  {/* defaultValue= specified here not value= https://reactjs.org/docs/uncontrolled-components.html */}
                  <input className="form-control" defaultValue={asJSON?asJSON.email:''} type="text" placeholder="email" readOnly={true}/>
              </div>

              <CustomInput label='first name'>
                  <Field name='firstName' type="text"/>
              </CustomInput>
              <CustomInput label='last name'>
                  <Field name='lastName' type="text"/>
              </CustomInput>
              <CustomCheckbox label='receive newletter'>
                  <Field name='newsletter' type="checkbox"/>
              </CustomCheckbox>

              <div className="form-group">
                {/* TODO work out how to combine these two ServerRequestAlerts */}
                <ServerRequestAlert id="form-error"/>
                <ServerRequestAlert response={fetchResponse} />
              </div>
              <div className="form-group">
                <FormikSubmit text="Save changes"/>
              </div>
            </Form>
        )}}
        </Formik>
      </NarrowForm>

      <NarrowForm>
        <h2>2 Factor Authentication</h2>
        <div className="form-group">
            { asJSON && asJSON.verified2fa===false  ? 
              (<div className="row">
                <div className='col'>
                  <Alert title="2FA is not enabled" msg="Use Google Authenticator or equivalent to give enhanced security to your account" class="alert-info"/>
                </div>
                <div className='col'><Link to="/2fa" className="btn btn-secondary">Enable Now</Link></div>
              </div>)
              :
              (<div className="row">
                <div className='col'>
                  <Alert title="2FA is enabled" msg="To disable please reset your password" class="alert-success"/>
                </div>
                <div className='col'><Link to="/forgot" className="btn btn-secondary">Reset password</Link></div>
              </div>)
            }
        </div>
      </NarrowForm>
      </div>
    </div>
  );
};