import React from "react";
import NarrowForm from '../components/narrowForm';
import { Formik, Form, Field } from 'formik'
//import { Link } from "react-router-dom";
import imperativeFetch from '../services/imperativeFetch';
import ServerRequestAlert, { setFetchResponseError,resetFetchResponseError } from '../components/ServerRequestAlert';
import FormikSubmit from '../components/formikSubmit';
import Breadcrumb from '../components/breadcrumb'

export default (props) => {

  const query=props.location.search;
  //console.log(query);
  var url = new URL("http:a/"+query);
  const email=url.searchParams.get('email');
  const token=url.searchParams.get('token');

  const onSubmit= (values,actions)  => {
    resetFetchResponseError('#form-error');
    var data = new URLSearchParams(values);
    let resp = imperativeFetch('/api/v1/forgot-password-reset', { method: 'post', body: data }, null);
		resp.then((res) => {
			setFetchResponseError(res, '#form-error',(<span>Password successfully reset. Please <a href="/login">login</a>.</span>) );
    });
  };

  return (
    <div>
      <Breadcrumb path={ [ {name: "Reset Password"}]} />
      <div className="container">
        <NarrowForm>
          <Formik initialValues={{email: email, token: token, password: ''}} onSubmit={ onSubmit} >
          {formik => { return (
            <Form>
              <input name="token" type="hidden"/>
              <div className="form-group">
                <label >email</label>
                <Field name='email' className='form-control' type='text' disabled={true}/>
              </div>
              <div className="form-group">
                <label >new password</label>
                <Field name='password' className='form-control' type='password'/>
              </div>
              <div className="form-group">
                <ServerRequestAlert id="form-error" />
              </div>
              <div className="form-group">
                <FormikSubmit text="Reset password"/>
              </div>
            </Form>
          )}}
          </Formik>
        </NarrowForm>
      </div>
    </div>
    
  );
};
