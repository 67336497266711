import React from "react";
import NarrowForm from '../components/narrowForm';
import { Formik, Form, Field } from 'formik'
import * as Yup from "yup";
import { CustomInput } from '../components/customInput';
import  { Alert } from '../components/ServerRequestAlert';
import FormikSubmit from '../components/formikSubmit';

const sleep = ms => new Promise(r => setTimeout(r, ms));

export default (props) => {

  const onSubmit = async (values, actions) => {
    console.log(values);
    await sleep(500);
    //actions.setSubmitting(false);
  };

  const FormSchema = Yup.object().shape({
    a: Yup.string()
      .min(2, 'Too Short!')
      .max(10, 'Too Long!')
      .required('Required'),
    b: Yup.number()
      .min(2, 'Too Low!')
      .max(10, 'Too High!')
      .required('Required'),
      c: Yup.string()
      .max(10, 'Too Long!')
      .required('Required'),
  });

  return (
    <NarrowForm>
      <h2>Form Validation</h2>
      <Alert title="About this page" msg="This page contains a form used for experimenting this new form techniques." class="alert-info"/>
      <Alert title="title" msg={(<div>stuff here <a href="/">link</a></div>)}/>

      <Formik initialValues={{ a: '', b: '', c: '' }}
        validateOnChange={false}
        onSubmit={onSubmit}
        validationSchema={FormSchema}
      >
        {formik => {
          //console.log(formik);
          return (
            <Form>

              <CustomInput label='Value A'>
                  <Field name='a' type="text" placeholder="this is a"/>
              </CustomInput>
              <CustomInput label='Password'>
                  <Field name='b' type="password" placeholder="this is a password"/>
              </CustomInput>
              <CustomInput label='Value C'>
                  <Field name='c' type="password" placeholder="this is c"/>
              </CustomInput>
              <div className="form-group">
                <FormikSubmit text="press me"/>
              </div>
            </Form>
          )
        }}
      </Formik>
    </NarrowForm>
  );
};