import React from "react";
import Breadcrumb from '../components/breadcrumb'
import NarrowForm from '../components/narrowForm';
import { Formik, Form, Field } from 'formik'
import FormikSubmit from '../components/formikSubmit';
import { Alert } from '../components/ServerRequestAlert';

export default (props) => {

  const query=props.location.search;
  var url = new URL("http:a/"+query);
  const email=url.searchParams.get('email');
  const token=url.searchParams.get('token');
  const formAction = process.env.REACT_APP_API_HOST+"/api/v1/signup-complete";

  const onSubmit= (values,actions)  => {
    //old fashioned form submission to go back to API
    document.getElementById("signupPassword").submit();
  };

  return (
    <div>
      <Breadcrumb path={ [ {name: "Choose an account password"}]} />
      <div className="container">
        <div className="row">
          <NarrowForm>
            <Alert msg="Nearly there! Please choose a password to complete account activation." class="alert-info"/>
            <Formik initialValues={{email: email, token: token, password: ''}} onSubmit={ onSubmit} >
            {formik => { return (
              <Form id="signupPassword" action={formAction}>
                <Field name="token" type="hidden"/>
                <div className="form-group">
                  <label >email</label>
                  <Field name='email' className='form-control' type='text' readOnly={true}/>
                </div>
                <div className="form-group">
                  <label >password</label>
                  <Field name='password' className='form-control' type='password' placeholder="please choose a password"/>
                </div>
                <div className="form-group">
                  <FormikSubmit text="Activate account"/>
                </div>
              </Form>
            )}}
            </Formik>
          </NarrowForm>
        </div>
      </div>
    </div>
  );
};