/*2fa.jsx*/
import React from "react";
import { Formik, Form, Field } from 'formik'
import NarrowForm from '../components/narrowForm';
import useFetch from '../services/useFetch';
import imperativeFetch from '../services/imperativeFetch';
import * as Yup from "yup";
import { CustomInput } from '../components/customInput';
import ServerRequestAlert, { setFetchResponseError, resetFetchResponseError, Alert } from '../components/ServerRequestAlert';
import AuthContext from '../services/authentication';
import { useContext } from "react";
import FormikSubmit from '../components/formikSubmit';

//Functional Component 
const TwoFactorAuthPage = () => {

  const useAuth = useContext(AuthContext);
  const fetchResponse = useFetch('/api/v1/initialise-2fa', {
    method: 'post',
  });
  // eslint-disable-next-line no-unused-vars
  const {response,loading,error,asJSON} = fetchResponse;

  const onSubmit = (values, actions) => {
    var data = new URLSearchParams();
		data.set('code', values.code);
    resetFetchResponseError('#form-error');
		let resp = imperativeFetch('/api/v1/verify-2fa', { method: 'post', body: data }, useAuth);
		resp.then((res) => {
      console.log(res);
      setFetchResponseError(res, '#form-error', res.asJSON.message);
      if (!(res.asJSON && res.asJSON.success)) {
        actions.setSubmitting(false);
        actions.resetForm({ values });
      }
    })
  };

  const FormSchema = Yup.object().shape({
		code: Yup.string()
      .required('Required')
      .length(6,"Code should be 6 digits"),
  });

  return (
    <div className="container">
    <NarrowForm>
			<h2>2 Factor Authentication Setup</h2>
      {asJSON!==null ? <div>
      { response && response.ok ?
        <div>
          <div className="alert alert-info">
            <p>Please scan the QR code or click the link to set up your authenticator app.</p>
            <p><a href={asJSON.url}>Setup Authentication App Link</a></p>
          </div>
          <center>
            <AuthQR>{asJSON.qr}</AuthQR>
          </center>
          <Formik initialValues={{ username: '', pwd: '', code: '', enable2fa: false }}
            onSubmit={onSubmit} validationSchema={FormSchema} >
            {formik => (
              <Form>
                <CustomInput label='Confirm Code'>
							    <Field name='code' type="text" placeholder="please enter your 6 digit code" />
						    </CustomInput>
                <div className="form-group">
                  <ServerRequestAlert id="form-error" />
                </div>
                <div className="form-group">
                  <FormikSubmit text="Complete setup"/>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        :
        <Alert title="Two factor authentication already set up" msg="To reset 2 factor authentication you must reset password" />
      }
      </div> : <div/>
      }
    </NarrowForm>
    </div>
  );
};


  const AuthQR = (props) => {
    const decode = () => {
      const url = "data:image/png;base64," + props.children;
      return url;
    }

    return (
      <div>
        <img id="qr" alt="QR Code" src={decode()} />
      </div>
    );
  }


export default TwoFactorAuthPage;