/*index.jsx*/
import React from "react";
import { useContext } from "react";
import AuthContext from '../services/authentication';
import $ from 'jquery';

export default (props) => {
  const useAuth = useContext(AuthContext);

  const onClick = () => {
    console.log("click");

    var apiHost = process.env.REACT_APP_API_HOST;
    var authToken = useAuth.token;
    console.log(authToken);
    var errorBlock = $('#message');
    $.ajax({
      url: apiHost + '/api/v1/test',
      type: 'post',
      headers: { Authorization: authToken },
      data: {  },
      dataType: 'text',
      success: function(data) {
        console.log(data);
        errorBlock.css("display","block").removeClass("alert-danger").addClass("alert-success").text("success");
      }
    })
    .fail(function (response) {
      console.log(response);
      const error=response.responseText;
      errorBlock.removeClass("alert-success").addClass("alert-danger");
      errorBlock.text(error);
    });


  }

  return (
    <div>
      <h2>Test Authorization</h2>
      <div id="message" className="alert alert-info">Press button to test. This server call should succeed if you are logged in and fail if you are not.</div>
      <button onClick={onClick} className="btn btn-primary btn-block">Test</button>
      
    </div>
  );
};