/*index.jsx*/
import React from "react";
import { Link } from "react-router-dom";
import Breadcrumb from '../components/breadcrumb'

export default (props) => {
  return (
    <div>
      <Breadcrumb path={ [ {name: "Account activated"}]} />
      <div className="container">
        <div className="col-sm-12">     
          <div className="alert alert-info" role="alert">
            Thank you for signing up and your interest in Tsuga Audio! You can now login.
          </div>
          <div>
            <Link className="btn btn-primary" to="/login">Login</Link></div>
        </div>
      </div>  
    </div>
  );
};