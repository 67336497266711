import React from 'react'
import { Formik, Form, Field } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'
import $ from 'jquery';
import NarrowForm from '../components/narrowForm';
import imperativeFetch from '../services/imperativeFetch';
import ServerRequestAlert, { setFetchResponseError } from '../components/ServerRequestAlert';
import * as Yup from "yup";
import { CustomInput } from '../components/customInput';
import Breadcrumb from '../components/breadcrumb'

const recaptchaRef = React.createRef()

export default props => {

	const FormSchema = Yup.object().shape({
		firstName: Yup.string()
			.required('Required'),
		lastName: Yup.string()
			.required('Required'),
		email: Yup.string()
			.required('Required'),
		pwd: Yup.string()
			.required('Required')
			//.matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
			//	"Must contain at least 8 characters, uppercase, lowercase, a digit, and one punctuation character")
			,
		pwd2: Yup.string()
			.required('Required')
			.oneOf(
				[Yup.ref('pwd')],
				'Passwords do not match',
			  ),
	});

	const onSubmit = (values) => {
		var captcha = recaptchaRef.current.getValue();
		var data = new URLSearchParams();
		data.set('firstName', values.firstName);
		data.set('lastName', values.lastName);
		data.set('email', values.email);
		data.set('password', values.pwd);
		data.set('captcha', captcha);
		let resp = imperativeFetch('/api/v1/signup', { method: 'post', body: data });
		resp.then((res) => {
			//console.log(res);
			setFetchResponseError(res, '#form-error');
			if (res.response != null && res.response.ok && res.asJSON != null) {
				var errorBlock = $('#form-error');
				if (res.asJSON.success) {
					errorBlock.css("display", "block");
					errorBlock.removeClass().addClass("alert alert-success");
					errorBlock.text(res.asJSON.message);
				} else {
					let msg= res.asJSON.message;
					if (msg==='email address already registered') {
						msg+='. if you forgot your password please request reset.';
					}
					errorBlock.css("display", "block");
					errorBlock.removeClass().addClass("alert alert-danger");
					errorBlock.text(msg);
				}
			}
		});

	}

	var initialValues = { username: '', email: '', pwd: '' }
	const formik = (
		<Formik
			initialValues={initialValues}
			validationSchema={FormSchema}
			onSubmit={onSubmit}
		>
			<Form>
				<CustomInput label='Email address'>
					<Field name='email' placeholder="please enter your email address" />
				</CustomInput>
				<CustomInput label='First name'>
					<Field name='firstName' placeholder="please enter your first name" />
				</CustomInput>
				<CustomInput label='Last name'>
					<Field name='lastName' placeholder="please enter your last name" />
				</CustomInput>
				<CustomInput label='Password'>
					<Field name='pwd' type="password" placeholder="please enter a password" />
				</CustomInput>
				<CustomInput label='Confirm Password'>
					<Field name='pwd2' type="password" placeholder="please type password again to confirm" />
				</CustomInput>

				<div className="form-group">
					<ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_CAPTCHA_SITEKEY} />	
				</div>
				<div className="form-group">
					<button type='submit' className="btn btn-primary btn-block" >Sign Up</button>
				</div>
				<div className="form-group">
					<ServerRequestAlert id="form-error" />
				</div>
			</Form>
		</Formik>
	)

	return (
		<div>
      		<Breadcrumb path={ [ {name: "Sign Up"}]} />
			<div className="container">
				<NarrowForm>
					{formik}
				</NarrowForm>
			</div>
		</div>
		
	)
}
