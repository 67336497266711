import React from "react";
import { Alert } from '../components/ServerRequestAlert';
import Breadcrumb from '../components/breadcrumb'

export default () => {
  return (
    <div>
      <Breadcrumb path={ [ {name: "404"}]} />
      <div className="container top-margin">
        <Alert class="alert-info" title="404 Page not Found" msg={
          <div>It looks like you may have followed a bad link.</div>
        } />
      </div>
    </div>
  );
};
