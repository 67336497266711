
import React from "react";
import Breadcrumb from '../components/breadcrumb'
import useFetch from '../services/useFetch';
import ServerRequestAlert from '../components/ServerRequestAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faApple, faWindows } from '@fortawesome/free-brands-svg-icons'
import { Link, NavLink } from "react-router-dom";

export default (props) => {

  const fetchResponse = useFetch('/api/v1/licensing/productInfo', {
    method: 'get',
    needAuth: false
  });
  // eslint-disable-next-line no-unused-vars
  var {response,loading,error,asJSON} = fetchResponse;

  const DownloadLink = ({ url, fileName }) => {
    const handleDownload = () => {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          //link.download = fileName || "downloaded-file";
          document.body.appendChild(link);
  
          link.click();
  
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error fetching the file:", error);
        });
    };
  
    return (
      <div>
        <button type="primary" onClick={handleDownload}>
          Download Sample JSON
        </button>
      </div>
    );
  };

  const ProductTable = (props) => {

    //console.log(props);
    if (props.data && props.data.product)
    {
      return <div className="product-table">
        { props.data.product.map( function(product, index) {
            var hasMac=product.downloadMacURL && product.downloadMacURL!=null;
            var hasWin=product.downloadWindowsURL && product.downloadWindowsURL!=null;
            var hasNotes=product.noteURL && product.noteURL!=null;
            
            return <div key={index} className="row">
              <div className="col-sm-4">
                <img src={product.imageURL} alt="product" className="img-fluid"/>
              </div>
              <div className="col-sm-5">
                <div className="title">{product.name} <span className="product-version">(v{product.latest_version})</span></div>
                <p className="desc">{product.description}</p>
                <p>
                  <a href={product.pageURL} >Product Page</a>
                  <span> / </span>
                  <a href={product.noteURL} className={(hasNotes?"":" disabled")}>Release Notes</a>
                </p>
              </div>
              <div className="col-sm-3">
                <p><NavLink to={"/downloadStarted?name="+product.name+"&url="+product.downloadMacURL} 
                  className={"btn btn-primary"+(hasMac?"":" disabled")}>
                  Download for <FontAwesomeIcon icon={faApple} /> MacOS</NavLink>
                </p>

                <p><NavLink to={"/downloadStarted?name="+product.name+"&url="+product.downloadWindowsURL}
                  className={"btn btn-primary"+(hasWin?"":" disabled")}>
                  Download for <FontAwesomeIcon icon={faWindows} /> Windows</NavLink>
                </p>
              </div>
            </div>;
        })}
        </div>;
    }
    return ( <div/> );
  };
  
  return (
    <div>
      <Breadcrumb path={ [ {name: "Downloads"}]} />
      <div className="container top-margin">
        {/*<div className="row">
          <div className="col-sm-12">fastspring
            <button data-fsc-item-path-value="envosound" data-fsc-action="Add, Checkout">Buy Envosound</button>
          </div>
        </div>*/}
        <div className="row">
          <div className="col-sm-12">
            <ServerRequestAlert response={fetchResponse} />
            <ProductTable data={asJSON}/>
          </div>
        </div>
      </div>
    </div>
  );
};