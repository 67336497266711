import React from "react";
import { useFormikContext } from 'formik';

export default (props) => {
	const { isValid, dirty, isSubmitting } = useFormikContext();
  var text=props.text?props.text:"submit";
  return (
	<button type='submit' className="btn btn-primary btn-block"
        disabled={!(isValid && dirty) || isSubmitting}>
		{text}
	</button>
  );
};
