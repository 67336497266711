import React from "react";
import logo from '../image/TsugaAudio_005b-green.png';
import LoginStatus from './loginStatus';
import { NavLink } from "react-router-dom";
import { useEffect } from "react";

const Template = (props) => {
	const collapse = () => {
		var toggle=document.getElementById("toggler");
		if (toggle.offsetParent != null)
		{	toggle.click();
		}
	};

	const shrinkNav = () => {
		var navBar = document.getElementById("navbar");
		if (document.documentElement.scrollTop > 50) {
            navBar.classList.add("top-nav-short");
        } else {
            navBar.classList.remove("top-nav-short");
        }
	};

	useEffect(() => {
		window.addEventListener("scroll", shrinkNav);
	});

	return (
		<div>
			<nav id="navbar" className="navbar fixed-top navbar-expand-lg navbar-custom navbar-dark bg-dark">
				<div className="container-fluid">
				<a className="navbar-brand" href={process.env.REACT_APP_MAIN_SITE}>
					<img src={logo} width="150" className="d-inline-block align-top" alt="" style={{marginRight: '0.25em'}}/>
 				 </a>
				
				  <button id="toggler" className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      				<span className="navbar-toggler-icon"></span>
    			</button>
				<div className="collapse navbar-collapse" id="navbarNav">
					<div className=" me-auto"/>
					<div className="d-flex">
						<ul className="navbar-nav me-auto">
							<li className="nav-item">
								<NavLink className="nav-link" activeClassName="active" to="/downloads" onClick={collapse}>DOWNLOADS</NavLink>
							</li>
							<li className="nav-item">
								<NavLink className="nav-link" activeClassName="active" to="/licenses" onClick={collapse}>LICENSES</NavLink>
							</li>
							<li className="nav-item">
								<NavLink className="nav-link" activeClassName="active" to="/orders" onClick={collapse}>ORDERS</NavLink>
							</li>
							{/*<li className="nav-item">
								<Link className="nav-link" to="/developer">Developer</Link>
							</li>*/}
						</ul>
						<LoginStatus/>
					</div>
				</div>
				</div>
			</nav>

			<div className="main-content">
				{props.content}
			</div>
		</div>
	);
};

export default Template;