import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faCircleXmark } from '@fortawesome/free-solid-svg-icons'

export default (props) => {
	const show= (props.show !== undefined)?props.show:true;
	if (show)
	{	return (
			<span className={props.valid?"icon-valid":"icon-invalid"}>
				<FontAwesomeIcon icon={(props.valid)?faCheckCircle:faCircleXmark} />
				<span> </span>
			</span>
		);
	}
	else
	{	return null;
	}
  };