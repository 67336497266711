import React from "react";
import Breadcrumb from '../components/breadcrumb'
import ServerRequestAlert, { Alert } from '../components/ServerRequestAlert';
import useFetch from '../services/useFetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReceipt } from "@fortawesome/free-solid-svg-icons";

export default () => {

  const fetchResponse = useFetch('/api/v1/licensing/orderInfo', {
    method: 'get',
  });
  // eslint-disable-next-line no-unused-vars
  var {response,loading,error,asJSON} = fetchResponse;

  const PurchaseTable = (props) => {
    return ( <div> 
      { props.data.map(function(purchase, index) {
        return <div className="row product-table" key={index}>
          <div className="col-sm-2"/>
          <div className="col-sm-2">
            <img src={purchase.img} alt="product" className="img-fluid"/>
          </div>
          <div className="col-sm-4">
            <div className="title"><a href={purchase.page}>{purchase.product_name}</a></div>
            <div>{purchase.type}</div>
          </div>
          <div className="col-sm-2">{purchase.price_value.toFixed(2)} {props.currency}</div>
        </div>;
      }) }
    </div> );
  };

  const OrderTable = (props) => {
    if (props.data && props.data.order)
    {
      if (props.data.order.length>0) {
      return ( <div>
        { props.data.order.map(function(order, index) {
            var fd =  new Date(order.timestamp).toLocaleDateString();
            return <div key={index}>
              <div className="row" >
              <div className="col-sm-2 order-big">{fd}</div>
                <div className="col-sm-6 order-big">{order.id_ext}</div>
                <div className="col-sm-2">Total: {order.total.toFixed(2)} {order.currency}</div>
                <div className="col-sm-2"><a href={order.url} className={"btn btn-dark "+(order.url===null && 'disabled')}>View Receipt <FontAwesomeIcon icon={faReceipt} /></a></div>
              </div>
              <PurchaseTable data={order.purchase} currency={order.currency}/>
              <hr/>
              </div>;
          })
        }
      </div> );
      } else {
        return (<Alert class="alert-info" title="You have no orders yet" msg={
          <div>Your orders will appear here. To purchase products please visit the <a href={process.env.REACT_APP_MAIN_SITE}>product page</a>.</div>
        } />);
      }
    }
    else {
      return ( <div/> );
    }
  };

  return (
    <div>
       <Breadcrumb path={ [ {name: "Orders"}]} />
       <div className="container top-margin">
      
          <div className="row">
            <div className="col-sm-12">
              <ServerRequestAlert response={fetchResponse} />
              <OrderTable data={asJSON}/>
            </div>
          </div>
        </div>
    </div>
   

  );
};
