import React from "react";
import NarrowForm from '../components/narrowForm';
import { Formik, Form, Field } from 'formik'
import imperativeFetch from '../services/imperativeFetch';
import ServerRequestAlert, { setFetchResponseError, resetFetchResponseError } from '../components/ServerRequestAlert';
import * as Yup from "yup";
import FormikSubmit from '../components/formikSubmit';
import Breadcrumb from '../components/breadcrumb'

export default (props) => {

  const onSubmit= (values,actions)  => {
    var data = new URLSearchParams(values);
    //console.log(data.toString());
    let resp = imperativeFetch('/api/v1/forgot-password', { method: 'post', body: data }, null);
    resetFetchResponseError('#form-error');
		resp.then((res) => {
			setFetchResponseError(res, '#form-error',"Password reset email sent. Please check your inbox." );
      actions.setSubmitting(false);
    });
  };

  const FormSchema = Yup.object().shape({
    email: Yup.string()
    .email()
    .required('Required'),
  });

  return (
    <div>
      <Breadcrumb path={ [ {name: "Forgot Password"}]} />
      <div className="container">
        <NarrowForm>
          <Formik initialValues={{email: ''}} onSubmit={onSubmit} validationSchema={FormSchema}>
          {formik => { return (
            <Form>
              <div className="form-group">
                <label >email</label>
                <Field name='email' className='form-control' type='text' placeholder="please enter the email address you used to sign up with"/>
              </div>
              
              <div className="form-group">
                <FormikSubmit text="Request Password Reset"/>
              </div>
              <div className="form-group">
                <ServerRequestAlert id="form-error" />
              </div>
            </Form>
          )}}
          </Formik>
        </NarrowForm>
      </div>
    </div>
    
  );
};
