/*index.jsx*/
import React from "react";
import NarrowForm from '../components/narrowForm';
import { Formik, Form, Field } from 'formik'
import $ from 'jquery';
import { Link } from "react-router-dom";
import { useContext } from "react";
import AuthContext from '../services/authentication';
import imperativeFetch from '../services/imperativeFetch';
import ServerRequestAlert, { Alert, setFetchResponseError } from '../components/ServerRequestAlert';
import * as Yup from "yup";
import { CustomInput } from '../components/customInput';
import FormikSubmit from '../components/formikSubmit';
import Breadcrumb from '../components/breadcrumb'

const LoginPage = (props) => {
	const useAuth = useContext(AuthContext);

	const onSubmit = (values, actions) => {
		//console.log(values);

		var data = new URLSearchParams();
		data.set('email', values.email);
		data.set('password', values.pwd);
		data.set('rememberme', values.rememberme);
		data.set('code', values.code);
		let resp = imperativeFetch('/api/v1/login', { method: 'post', body: data }, null);
		resp.then((res) => {
			console.log(res);
			setFetchResponseError(res, '#form-error');
			const resp = res.response;
			if (resp != null && resp.ok && res.asJSON != null) {
				const r = res.asJSON;
				

				if (r.success) {
					useAuth.login(r.message);

					//https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/location.md
					var url = '/';
					var location = props.location.state;
					if (location) {
						var redirect = location.redirect;
						if (redirect) {
							url = redirect.pathname + redirect.search;
						}
					}
					props.history.push(url);
				}
				else {
					if (r.need2FA) {
						actions.setFieldValue('enable2fa', true);
						$("input[name='code']").focus();
					}
				}
				actions.setSubmitting(false);
			}
		});

	};

	const FormSchema = Yup.object().shape({
		email: Yup.string()
			.required('Required'),
		pwd: Yup.string()
			.required('Required'),
		code: Yup.string()
			.when('enable2fa', {
				is: true,
				then: Yup.string()
					.matches('^\\d{6}$','6 digit code')
			}),
	});

	const redirectAlert = () => {
		if (props.location.state && props.location.state.redirect) {
			return <Alert msg="This page requires an account to access. Please login to view." class="alert-info"/>;
		}
		else { return <span /> }
	};

	return (
		<div>
      		<Breadcrumb path={ [ {name: "Login"}]} />
			<div className="container top-margin">
				<NarrowForm>
					{redirectAlert()}
					<Formik initialValues={{ email: '', pwd: '', rememberme: '', code: '', enable2fa: false }}
						onSubmit={onSubmit} validationSchema={FormSchema} >
						{formik => (
							<Form>
								<CustomInput label='Email'>
									<Field name='email' type="text" placeholder="please enter your email address" />
								</CustomInput>
								
								<CustomInput label='Password'>
									<Field name='pwd' type="password" placeholder="please enter your password" />
								</CustomInput>

								<div className="form-check form-group">
									<Field type="checkbox" className="form-check-input" name="rememberme"/>
									<label className="form-check-label" htmlFor="rememberme">Remember me</label>
								</div>

								{formik.values.enable2fa ?
									<CustomInput label='Two factor authentication code'>
										<Field name='code' type="text" placeholder="please enter the current code from your 2FA app" />
									</CustomInput>
									: <div />
								}

								<div className="form-group">
									<ServerRequestAlert id="form-error" />
								</div>
								
								<div className="form-group">
									<FormikSubmit text="Login"/>
									<span style={{paddingLeft: "1em"}}/>
									<Link to="/forgot">Forgot password?</Link>
								</div>
								
							</Form>
						)}
					</Formik>
				</NarrowForm>
			</div>
		</div>
	);
};

export default LoginPage;