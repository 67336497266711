import React from 'react'
import useFetch from '../services/useFetch';
import Breadcrumb from '../components/breadcrumb'
import ServerRequestAlert, { Alert, setFetchResponseError } from '../components/ServerRequestAlert';
import imperativeFetch from '../services/imperativeFetch';
import { useContext } from "react";
import AuthContext from '../services/authentication';
import $ from 'jquery';

export default props => {
	const useAuth = useContext(AuthContext);

	const onAdd = () => {
		console.log("click");

		let resp= imperativeFetch('/api/v1/licensing/purchaseFreeComplete?'+data.toString(),{method:'get'},useAuth);
		resp.then((res) => {
			//
			setFetchResponseError(res,'#form-error');
			if (res.response!==null && res.response.ok===true) {
				$('#form-error').css('display','block').removeClass().addClass('alert alert-info').html(
					"<p>License successfully added to your account. View <a href='/licenses/'>here</a>."
				);
			}
		});

	};

	const CheckStatus = (props) => {
		var p=props.data;
		console.log(p);
		if (props.data) {
			return (<div className="row product-table">
					<div className="col-sm-2">
						<img src={p.imageURL} alt="product" className="img-fluid"/>
					</div>
					<div className="col-sm-5">
						<div className="title">{p.name}</div>
						<p className="desc">{p.description}</p>
					</div>
					<div className="col-sm-4">
						{ p.available ?
						<button className="btn btn-primary" disabled={!p.available} onClick={()=>onAdd(p.id)}>Add free license to account</button> :
						<Alert class="alert-info" msg="Sorry, you cannot get this license. Maybe you already have it?" /> }
					</div>
				</div>);
		} else {
			return <div/>;
		}
	};

	var pathname=props.location.pathname;
	//console.log(pathname);
	var i=pathname.lastIndexOf('/');
	var productId = i>=0?pathname.substring(i+1):"";
	//console.log(productId);
	if (productId.length===0 || isNaN(parseInt(productId))) {
		return <div/>;
	} else {
		var data = new URLSearchParams();
		data.set('id', productId);
		const fetchResponse = useFetch('/api/v1/licensing/purchaseFreeCheck?'+data.toString(), {
			method: 'get',
		  });
		var {response,loading,error,asJSON} = fetchResponse;

		return (<div>
			<Breadcrumb path={ [ {name: "Purchase Free"}]} />
			<div className="container top-margin">
				<ServerRequestAlert response={fetchResponse} />
				<ServerRequestAlert id="form-error"/>
				<CheckStatus data={asJSON}/>
			</div>
		</div>);
	}

	

};