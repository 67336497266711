import React from "react";
import ReactDOMServer from 'react-dom/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTriangleExclamation, faCircleInfo, faHourglass } from '@fortawesome/free-solid-svg-icons'
import $ from 'jquery';

const successIcon = ReactDOMServer.renderToStaticMarkup(<FontAwesomeIcon icon={faCheckCircle} />);
const failureIcon = ReactDOMServer.renderToStaticMarkup(<FontAwesomeIcon icon={faTriangleExclamation} />);
const infoIcon = ReactDOMServer.renderToStaticMarkup(<FontAwesomeIcon icon={faCircleInfo} />);
const waitIcon = ReactDOMServer.renderToStaticMarkup(<FontAwesomeIcon icon={faHourglass} />);

const clear = (element) => {
	element.css('display','none').removeClass().empty();
};

export const resetFetchResponseError = (id) => {
	clear($(id));
};

export const setFetchResponseError = (r,id,successMsg) => {
	const element = $(id);

	const setContent = (title,msg,alertClass) => {
		element.css('display','block').removeClass().addClass('alert '+alertClass).empty();
		var icon="";
		if (alertClass==="alert-success") icon=successIcon;
		if (alertClass==="alert-danger") icon=failureIcon;
		if (alertClass==="alert-info") icon=infoIcon;
		if (alertClass==="alert-wait") icon=waitIcon;
		if (title) element.append($("<div style='font-weight: bold'>"+icon+" "+title+"</div>"));
		if (msg) {
			if (title) icon="";
			if (React.isValidElement(msg))
			{	var html = icon+" "+ReactDOMServer.renderToStaticMarkup(msg);
				element.append(html);
			}
			else
			{	element.append($("<div>"+icon+" "+msg+"</div>"));
			}
		}
	};
	

	if (r.loading===true) {
		setContent("Waiting for response",'please wait...','alert-wait');
	}
	else if (r.error!==null) {
		setContent("Error issuing request to server",r.error,'alert-danger');
	}
	else if (r.response!==null && r.asJSON!=null && r.asJSON.success===false) {
		setContent(null, r.asJSON.message,'alert-danger');
	}
	else if (r.response!==null && r.response.status!==200) {
		const title="Error from server: "+r.response.status;
		setContent(title, r.response.statusText,'alert-danger');
	}
	else {
		var asJSON=r.asJSON;
		if (asJSON.success===false)
		{	setContent("Error from server", asJSON.message,'alert-danger');
		}
		else if (successMsg) {
			setContent(null, successMsg,'alert-success');
		} else {
			clear(element);
		}
	}
};

export const Alert = (props) => {
	var titleTag, msgTag, icon, cssClass;
	if (props.class) {
		cssClass=props.class;
	} else {
		cssClass="alert-danger";
	}

	if (cssClass==='alert-danger') {
		icon=<FontAwesomeIcon icon={faTriangleExclamation} />;
	} else if (cssClass==='alert-success') {
		icon=<FontAwesomeIcon icon={faCheckCircle} />;
	} else if (cssClass==='alert-info') {
		icon=<span className='icon-info'><FontAwesomeIcon icon={faCircleInfo} /></span>;
	} else if (cssClass==='alert-wait') {
		icon=<FontAwesomeIcon icon={faHourglass} />;
		cssClass='alert-info';
	}

	if (props.title)
		titleTag=<div className="title">{icon} {props.title}</div>;
	if (props.msg)
	{	if (props.title)
			msgTag=<div>{props.msg}</div>;
		else
			msgTag=<div>{icon} <span> </span>{props.msg}</div>;
	}
	cssClass="alert "+cssClass;
	return <div className={cssClass}>
		{titleTag}
		{msgTag}
	</div>;
};

export default (props) => {
	if (props.response) {
		const r=props.response;
		var block;

		if (r.loading) {
			block=<Alert msg="loading" class="alert-wait"/>
		}
		else if (r.error!==null) {
			block=<Alert title="Error issuing request to server" msg={r.error.toString()} />;
		}
		else if (r.response!==null && r.response.status===500 && r.asJSON!=null) {
			block=<Alert msg={r.asJSON.message} />;
		}
		else if (r.response!==null && r.response.status!==200) {
			const title="Error from server: "+r.response.status;
			block=<Alert title={title} msg={r.response.statusText} />;
		}
		else {
			block=<div style={{display: 'none' }}/>;
		}
		return block;
	}
	else {
		return (
			<div id={props.id} style={{display: 'none' }}/>
		);
	}
}
