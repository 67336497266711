import React from "react";
import imperativeFetch from '../services/imperativeFetch';
import AuthContext from '../services/authentication';
import { useContext } from "react";
import ServerRequestAlert, { setFetchResponseError } from '../components/ServerRequestAlert';
import $ from 'jquery';

export default (props) => {
	const useAuth = useContext(AuthContext);
	const click = () => {
		
		
		let resp= imperativeFetch('/api/v1/account',{method:'get'},useAuth);
		resp.then((res) => {
			//
			setFetchResponseError(res,'#form-error');
			if (res.response!==null && res.response.ok===true) {
				$('#form-error').css('display','block').removeClass().addClass('alert alert-success').text(
					"request was successful"
				);
			}
		});
	}

	return (
		<div>
			<h2>Auth Only Page</h2>
			<div className="alert alert-info">
				You should only be able to access this page when logged on.
				Clicking the button issues a request to server for account data.
			</div>
			<ServerRequestAlert id="form-error"/>
			<button onClick={click} className="btn btn-primary">Test</button>
		</div>);
}