
import React from "react";
import Breadcrumb from '../components/breadcrumb'
import Alert from '../components/ServerRequestAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faApple, faWindows } from '@fortawesome/free-brands-svg-icons'

export default (props) => {

  const query=props.location.search;
  var fakeurl = new URL("http:a/"+query);
  const downloadURL=fakeurl.searchParams.get('url');
  const productName=fakeurl.searchParams.get('name');
  const i=downloadURL.lastIndexOf('/');
  const downloadFile=downloadURL.substring(i+1);
  fetch(downloadURL, {
    method: 'GET',
  })
  .then((response) => response.blob())
  .then((blob) => {
    // Create blob link to download
    const url = window.URL.createObjectURL(
      new Blob([blob]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      downloadFile,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  });

  return (
    <div>
      <Breadcrumb path={ [ {name: "Download " + productName}]} />
      <div className="container top-margin">
        <div className="row">
          <div className="col-sm-12">
            <div class="alert alert-primary">
              Your download of <b>{productName} </b>should start now. If not, please click for following
              link <a href={downloadURL}>{downloadFile}</a>.
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};